<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Trajets annulés",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Trajets annulés",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets annulés",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itinéraire", sortable: true },
        { key: "conducteur", sortable: true },
        { key: "Depart", sortable: true },
        { key: "prix", sortable: true },
        { key: "passagers", sortable: true },
        { key: "motif", sortable: true },
      ],
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetAnnule: [],
      newStart: false,
      recherche:"",
      typeOfCase:[],
      parBon:500
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    //Chargement des données
    const data = await apiRequest(
      "GET",
      "admin/trajets/cancel",
      undefined,
      false
    );
    if (data && data.data) {
    
      const formattedTable = data.data.trajets.map((trajet) => {
        var passagers = "";

        this.totalValue=data.data.total
        this.totalRows =data.data.total
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart.nom.split(",")[0] +
            " ↦  " +
            trajet.lieuArrivee.nom.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname +
            " " +
            trajet.conducteur.personne.firstname,
          Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          motif: trajet.motifAnnulation,
          passagers: passagers,
        };
      });
      this.tableData = formattedTable;
      this.totalRows = this.tableData.length;
    }
    // Set the initial number of items
 
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/trajets/details_annules/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },


    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/cancel?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.trajets.map((trajet) => {
        var passagers = "";

        this.totalValue=data.data.total
        trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        });
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart.nom.split(",")[0] +
            " ↦  " +
            trajet.lieuArrivee.nom.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname +
            " " +
            trajet.conducteur.personne.firstname,
          Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          motif: trajet.motifAnnulation,
          passagers: passagers,
        };
      });
        this.newDatTrajetAnnule = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

        this.userChargeLoader = false;
      }
    },

 

    async userSearch() {
 
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/trajets/recherche?page="+ this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },
        false
      );
     
      if (dataSearch && dataSearch.data) {

        const formattedTable = dataSearch.data.map((trajet) => {
        var passagers = "";


        trajet.passagers.length!=0? trajet.passagers.forEach((element) => {
          if (element.statut == "ACTIF") {
            passagers +=
              "-" + element.firstname + " " + element.lastname + "\n";
          }
        }):"Pas de Passager";
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart +
            " ↦  " +
            trajet.lieuArrivee,
          conducteur:
            trajet.conducteur.personne.lastname +
            " " +
            trajet.conducteur.personne.firstname,
          Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          motif: trajet.motifAnnulation,
          passagers: passagers
        };
      });
       
        this.newDatTrajetAnnule = formattedTable;
     
    
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
        
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
    
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
 
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;

      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
   
      this.actif(this.startValue, this.endValue);
    }


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="containerDot" v-if="tableData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
  

            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                  Total de trajets annulés  : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="p-3"></div>
              <!-- Search -->
              <div class="inputCol pr-3">
                <div class="input">
                  <input
                    type="search"
                    id="serchInput"
                    placeholder="Rechercher..."
                    v-model="recherche"
                    @keyup.enter="userSearch"
                  />
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-2">
              <b-table
                :items="newDatTrajetAnnule.length!=0?newDatTrajetAnnule: tableData"
                :fields="fields"
                responsive="sm"
          
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
      
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goToDetail"
              ></b-table>
            </div>
            <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == this.parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
   thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}


.input {
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 35px;
  top: 0;
  right: -30;

  flex: 60%;

  margin: 0 0 10px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
padding: 3px 3px 3px 8px;
position: absolute;
top: 0;
width: 100%;
height: 100%;
border: none;
outline: none;
font-size: 18px;
color: #495057;
font-size: 1rem;
line-height: 1.5;
font-weight: 500;
}
.input input:focus {
border: 3px solid #d6d2f8;
}
.inputCol{
position: relative;
justify-content: flex-end;
  margin-right: 13px;
align-items: flex-end;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}



.paging_simple_numbers{
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  padding: 10px 30px;
}
ion-icon{
  cursor: pointer;
}
.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -22%;
  left: 0;
  right: 0;
  margin: 0 auto;
}



.paging_simple_numbers{
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  padding: 10px 30px;
}

.myBouton{
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
  
}


</style>